import { MUTATIONS } from './types';

const mutations = {
  [MUTATIONS.OPEN_SNACKBAR] (state, payload) {
    const {
      message,
      timeout
    } = payload;

    state.messageSnackbar = message;
    state.timeoutSnackbar = timeout;
    state.showSnackbar = true;
  },

  [MUTATIONS.CLOSE_SNACKBAR] (state) {
    state.messageSnackbar = '';
    state.showSnackbar = false;
  },

  [MUTATIONS.LOGIN] (state, payload) {
    const {
      token,
      nomeUsuario,
      nomeEstabelecimento,
      idEstabelecimento,
      nick,
      printerName,
      printerType,
      machineId,
      soundPlay0,
      soundPlay1
    } = payload;

    state.isAuth = true;
    state.token = token;
    state.nomeUsuario = nomeUsuario;
    state.nomeEstabelecimento = nomeEstabelecimento;
    state.idEstabelecimento = idEstabelecimento;
    state.nick = nick;
    state.printerName = printerName;
    state.printerType = printerType;
    state.machineId = machineId;
    state.soundPlay0 = soundPlay0;
    state.soundPlay1 = soundPlay1;
  },

  [MUTATIONS.LOGOUT] (state) {
    state.isAuth = false;
    state.token = '';
    state.nomeUsuario = '';
    state.nomeEstabelecimento = '';
    state.idEstabelecimento = '';
    state.nick = '';
    state.printerName = '';
    state.printerType = '';
    state.machineId = '';
  },

  [MUTATIONS.SET_RUNTIME_VERSION] (state, payload) {
    const {
      runtimeVersion
    } = payload;

    state.runtimeVersion = runtimeVersion;
  },

  [MUTATIONS.SET_PRINTER_NAME] (state, payload) {
    const {
      printerName
    } = payload;

    state.printerName = printerName;
  },

  [MUTATIONS.SET_PRINTER_TYPE] (state, payload) {
    const {
      printerType
    } = payload;

    state.printerType = printerType;
  },

  [MUTATIONS.SET_MACHINE_ID] (state, payload) {
    const {
      machineId
    } = payload;

    state.machineId = machineId;
  },

  [MUTATIONS.SET_SOUND_PLAY_0] (state, payload) {
    const {
      soundPlay0
    } = payload;

    state.soundPlay0 = soundPlay0;
  },

  [MUTATIONS.SET_SOUND_PLAY_1] (state, payload) {
    const {
      soundPlay1
    } = payload;

    state.soundPlay1 = soundPlay1;
  },

  [MUTATIONS.SET_PRINTERS] (state, payload) {
    const { 
      printers
    } = payload;

    state.printers = printers;
  },
};

export default mutations;
