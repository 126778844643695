import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/views/Login';
import Home from '@/views/Home';
import ListaImpressoras from '@/views/ListaImpressoras';
import store from '@/store';
import { ROUTE_NAMES } from '@/router/names';
import { Types } from '@/store';

const { ACTIONS } = Types;

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: ROUTE_NAMES.LOGIN,
    component: Login,
  },
  {
    path: '/',
    name: ROUTE_NAMES.HOME,
    component: Home,
    meta:{
      auth: true
    }
  },
  {
    path: '/lista-impressoras',
    name: ROUTE_NAMES.LISTA_IMPRESSORAS,
    component: ListaImpressoras,
    meta:{
      auth: true
    }
  }  
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next)=>{
  try {
    await store.dispatch(ACTIONS.LOAD_LOGIN_INFO)
  } catch (e) {
    if(to.meta.auth){
      next({ 
        name: ROUTE_NAMES.LOGIN 
      })
      return
    }
  }
  next()
})

export { ROUTE_NAMES };

export default router;
