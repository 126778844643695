<template>
  <v-container>
    <main-toolbar></main-toolbar>
    <v-row justify="center">
      <v-col>
        <v-list
          subheader
          two-line
        >
          <v-subheader>Informações</v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Versão</v-list-item-title>
              <v-list-item-subtitle>{{ runtimeVersion }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Usuário</v-list-item-title>
              <v-list-item-subtitle>{{ nomeUsuario }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Máquina</v-list-item-title>
              <v-list-item-subtitle>{{ nick }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import MainToolbar from '@/components/MainToolbar';
import { Types } from '@/store';
const { GETTERS } = Types;

export default {
  components:{
    MainToolbar
  },

  computed:{
    ...mapGetters({
      nomeUsuario: GETTERS.NOME_USUARIO,
      nomeEstabelecimento: GETTERS.NOME_ESTABELECIMENTO,
      pedidos: GETTERS.PEDIDOS,
      ultimaConsulta: GETTERS.ULTIMA_CONSULTA,
      runtimeVersion: GETTERS.RUNTIME_VERSION,
      nick: GETTERS.NICK,
      printerName: GETTERS.PRINTER_NAME,
      printerType: GETTERS.PRINTER_TYPE,
      connectionIcon: GETTERS.CONNECTION_ICON,
      connectionColor: GETTERS.CONNECTION_COLOR,
      soundPlay0: GETTERS.SOUND_PLAY_0,
      soundPlay1: GETTERS.SOUND_PLAY_1
    }),
  }
}
</script>

<style>

</style>