class Cache {
  constructor(idEstabelecimento = '') {
    this.idEstabelecimento = idEstabelecimento;
    this.printerName = '';
    this.printerType = '';
    this.nick = '';
    this.nomeUsuario = '';
    this.nomeEstabelecimento = '';
    this.machineId = '';
    this.token = '';
    this.soundPlay0 = 1;
    this.soundPlay1 = 1;
    this.runtimeVersion = '';
  }

  async loadConfigCache() {
    let data = '{}';
    if (typeof getConfigCache === 'function') {
      // eslint-disable-next-line
      data = await getConfigCache();
    }
    const jsonData = JSON.parse(data);
    
    const {
      idEstabelecimento = '',
      printerName = '',
      printerType = '',
      nick = '',
      soundPlay0 = 1,
      soundPlay1 = 1
    } = jsonData;

    if (!this.idEstabelecimento) {
      this.idEstabelecimento = idEstabelecimento;
    }
    this.printerName = printerName;
    this.printerType = printerType;
    this.nick = nick;
    this.soundPlay0 = soundPlay0;
    this.soundPlay1 = soundPlay1;

    if (typeof getRuntimeVersion === 'function') {
      // eslint-disable-next-line
      const version = await getRuntimeVersion();
      this.runtimeVersion = version;
    }
  }

  async loadLoginCache() {
    if (typeof checkLoginCacheExists !== 'function') {
      return;
    }

    // eslint-disable-next-line
    const e = await checkLoginCacheExists();
    if (!e) {
      return;
    }

    let data = '{}';
    if (typeof getLoginCache === 'function') {
      // eslint-disable-next-line
      data = await getLoginCache();
    }
    const jsonData = JSON.parse(data);

    const {
      nomeUsuario = '',
      nomeEstabelecimento = '',
      machineId = '',
      token = ''
    } = jsonData;

    this.nomeUsuario = nomeUsuario;
    this.nomeEstabelecimento = nomeEstabelecimento;
    this.machineId = machineId;
    this.token = token;
  }

  async saveConfigCache() {
    const jsonData = {
      idEstabelecimento: this.idEstabelecimento,
      printerName: this.printerName,
      printerType: this.printerType,
      nick: this.nick,
      soundPlay0: this.soundPlay0,
      soundPlay1: this.soundPlay1
    };

    const data = JSON.stringify(jsonData);
    if (typeof saveConfigCache === 'function') {
      // eslint-disable-next-line
      await saveConfigCache(data);
    }
  }

  async saveLoginCache() {
    const jsonData = {
      idEstabelecimento: this.idEstabelecimento,
      nomeUsuario: this.nomeUsuario,
      nomeEstabelecimento: this.nomeEstabelecimento,
      machineId: this.machineId,
      token: this.token
    };

    const data = JSON.stringify(jsonData);
    
    if (typeof saveLoginCache === 'function') {
      // eslint-disable-next-line
      await saveLoginCache(data);
    }
  }
  async logout(){
    if (typeof logoutCache === 'function') {
      // eslint-disable-next-line
      await logoutCache();
    }
  }
}

export default Cache;
