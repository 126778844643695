<template>
  <v-app>
    <v-main>
      <router-view />
      <v-snackbar :value="showSnackbar">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="error"
            icon
            v-bind="attrs"
            @click="actionCloseSnackbar"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-footer app>
        <v-col
          class="text-center caption pa-0"
          cols="12"
        >
          © {{ new Date().getFullYear() }} — <strong>Place Tecnologia e Serviços Ltda </strong>
        </v-col>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { Types } from './store';

const { GETTERS, ACTIONS } = Types;

export default {
  name: 'App',

  computed: {
    ...mapGetters({
      message: GETTERS.MESSAGE_SNACKBAR,
      showSnackbar: GETTERS.SHOW_SNACKBAR,
    }),
  },

  methods:{
    ...mapActions({
      actionCloseSnackbar: ACTIONS.CLOSE_SNACKBAR,
    }),
  }
}
</script>

<style>
  html {
    overflow: auto !important
  }
</style>
