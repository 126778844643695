import { GETTERS } from './types';

const getters = {
  [GETTERS.SHOW_SNACKBAR]: state => state.showSnackbar,

  [GETTERS.MESSAGE_SNACKBAR]: state => state.messageSnackbar,

  [GETTERS.IS_AUTH]: state => state.isAuth,

  [GETTERS.TOKEN]: state => state.token,

  [GETTERS.NOME_USUARIO]: state => state.nomeUsuario,

  [GETTERS.NOME_ESTABELECIMENTO]: state => state.nomeEstabelecimento,

  [GETTERS.ID_ESTABELECIMENTO]: state => state.idEstabelecimento,

  [GETTERS.RUNTIME_VERSION]: state => state.runtimeVersion,

  [GETTERS.NICK]: state => state.nick,

  [GETTERS.PRINTER_NAME]: state => state.printerName,

  [GETTERS.PRINTER_TYPE]: state => state.printerType,

  [GETTERS.SOUND_PLAY_0]: state => state.soundPlay0 === 1,
  
  [GETTERS.SOUND_PLAY_1]: state => state.soundPlay1 === 1,

  [GETTERS.MACHINE_ID]: state => state.machineId,

  [GETTERS.PRINTERS]: state => state.printers,
};

export default getters;
