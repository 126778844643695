<template>
  <v-card>
    <v-card-title>
      <v-btn 
        icon 
        color="secondary"
        @click="$router.back()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>
        Impressoras cadastradas
      </span>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headersImpressoras"
        :items="printers"
        disable-pagination
        hide-default-footer
        item-key="id"
        :loading="loading"
      >
        <template v-slot:item.printer_default="{ item }">
          <v-simple-checkbox
            v-model="item.printer_default"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click.stop="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click.stop="editId = item.id; confirmDel = true"
        >
          mdi-delete
        </v-icon>
        <v-icon
          small
          @click="testPrinter(item)"
        >
          mdi-file-send
        </v-icon>
      </template>
      </v-data-table>
      <cadastro-impressora
        v-model="openCadastroModal"
        :edit-form="editForm"
        :edit-id="editId"
      ></cadastro-impressora>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="editId = null;openCadastroModal = true"
        color="info"
      >
        Cadastrar
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="confirmDel" max-width="210">
      <v-card>
        <v-card-title>
          Tem certeza que deseja excluir?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="confirmDel = false">Não</v-btn>
          <v-btn color="success" @click="deleteItem" :loading="loading">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CadastroImpressora from '../components/CadastroImpressora';
import { Types } from '../store';
import { OPTIONS_MARCAS_IMPRESSORAS } from '../utils/consts';
import { listPrinters } from '../utils/print';

const { ACTIONS, GETTERS } = Types;

export default {
  name: 'ListaImpressoras',

  components: {
    CadastroImpressora
  },

  data: () => ({
    headersImpressoras: [
      {
        text: 'Apelido',
        value: 'nick',
      },
      {
        text: 'Nome da Impressora',
        value: 'printer_name',
      },{
        text: 'Padrão',
        value: 'printer_default',
      },{
        text: 'Ações',
        value: 'actions',
        align: 'center',
        sortable: false
      },
    ],
    editForm:{
      printerName:'',
      printerType:'',
      nick:'',
      printerDefault:false
    },
    editId:null,
    printerNames: [],
    marcasImpressoras: OPTIONS_MARCAS_IMPRESSORAS,
    openCadastroModal: false,
    confirmDel: false,
    loading: false
  }),

  computed: {
    ...mapGetters({
      printers: GETTERS.PRINTERS,
    })
  },

  async mounted() {
    this.loading = true
    try {
      await this.actionFetchPrinters();
      const printerNames = await listPrinters()
      this.printerNames = printerNames
    } catch (error) {
      this.printerNames = []
    } finally {
      this.loading = false
      if(this.printers.length === 0){
        this.editId = null
        this.openCadastroModal = true
      }
    }
  },

  methods: {
    ...mapActions({
      actionFetchPrinters: ACTIONS.FETCH_PRINTERS,
      actionSetPrinterDefault: ACTIONS.SET_PRINTER_DEFAULT,
      actionUpdatePrinter: ACTIONS.UPDATE_PRINTER,
      actionDeletePrinter: ACTIONS.DELETE_PRINTER,
      actionTestPrinterReceipt: ACTIONS.TEST_PRINTER_RECEIPT,
      openSnackbar: ACTIONS.OPEN_SNACKBAR,
    }),
    setPrinterDefault(printerId) {
      this.actionSetPrinterDefault({ printerId });
    },
    updatePrinterName(printerId, printerName) {
      this.actionUpdatePrinter({ printerId, printerName });
    },
    updatePrinterType(printerId, printerType) {
      this.actionUpdatePrinter({ printerId, printerType });
    },
    editItem(item){
      this.editId = item.id
      this.editForm.printerName = item.printer_name
      this.editForm.printerType = item.printer_type
      this.editForm.printerDefault = item.printer_default 
      this.editForm.nick = item.nick 
      this.openCadastroModal = true
    },
    async deleteItem(){
      try {
        this.loading = true
        await this.actionDeletePrinter({ printerId: this.editId})
        await this.actionFetchPrinters()
        this.confirmDel = false
      } catch (error) {
        let msg
        if(error.response){
          msg = error.response.data.detail
        } else {
          msg = "Ocorreu algo inesperado"
        }
        this.openSnackbar({ message: msg, millisecs: 7000 })
      } finally {
        this.loading = false
      }
    },
    testPrinter(item){
      this.actionTestPrinterReceipt(item)
      this.openSnackbar({ message: "Impressão teste enviada", millisecs: 7000 })
    }
  }
}
</script>
