<template>
  <v-dialog
    v-model="openModal"
    persistent
    max-width="60%"
  >
    <v-card>
      <v-form 
        @submit.prevent="createPrinter" 
        v-model="formValid"
        ref="form"
      >
        <v-card-title>
          Cadastro de Impressora
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                :items="printers"
                v-model="printerName"
                label="Selecionar Impressora"
                :rules="[v => !!v || 'Campo obrigatório']"
                dense
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="marcasImpressora"
                v-model="printerType"
                label="Marca da Impressora"
                :rules="[v => !!v || 'Campo obrigatório']"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="nick"
                label="Apelido da Impressora"
                :rules="[v => !!v || 'Campo obrigatório']"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="printerDefault"
                :disabled="editId && editForm.printerDefault"
                label="Impressora Padrão"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="closeModal"
          >
            Fechar
          </v-btn>
          <v-btn
            color="success"
            text
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            Cadastrar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { OPTIONS_MARCAS_IMPRESSORAS } from '../utils/consts';
import { listPrinters } from '../utils/print';
import { Types } from '../store';

const { ACTIONS } = Types;

export default {
  name: 'CadastroImpressora',

  props: {
    value: {
      type: Boolean,
      required: true
    },
    editForm: {
      type: Object,
      required: true
    },
    editId: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    printerName: '',
    printerType: '',
    nick: 'Padrão',
    printerDefault: false,

    loading: false,
    formValid: false,
    marcasImpressora: OPTIONS_MARCAS_IMPRESSORAS,
    printers: []
  }),

  mounted() {
    // eslint-disable-next-line
    listPrinters()
      .then(printers => this.printers = printers)
      .catch(() => this.printers = []);
  },

  computed:{
    openModal:{
      set(v){
        this.$emit('input', v)
      },
      get(){
        return this.value
      }
    }
  },

  watch:{
    openModal(v){
      if(v && this.editId){
        this.printerName = this.editForm.printerName
        this.printerType = this.editForm.printerType
        this.nick = this.editForm.nick
        this.printerDefault = this.editForm.printerDefault
      } else {
        this.printerName = ''
        this.printerType = ''
        this.nick = 'Padrão'
        this.printerDefault = false
        this.$refs.form.resetValidation()
      }
    }
  },

  methods: {
    ...mapActions({
      actionCreatePrinter: ACTIONS.CREATE_PRINTER,
      actionFetchPrinters: ACTIONS.FETCH_PRINTERS,
      actionUpdatePrinter: ACTIONS.UPDATE_PRINTER,
      actionSetPrinterDefault: ACTIONS.SET_PRINTER_DEFAULT,
    }),
    closeModal() {
      this.openModal = false
    },
    async createPrinter() {
      this.$refs.form.validate();
      if (!this.formValid) {
        return;
      }
      this.loading = true

      const data = {
        nick: this.nick.trim(),
        printerName: this.printerName,
        printerType: this.printerType,
        printerDefault: this.printerDefault,
        printerId: this.editId
      };
      try {
        if(this.editId){
          await this.actionUpdatePrinter(data)
          if(!this.editForm.printer_default && this.printerDefault){
            await this.actionSetPrinterDefault({ printerId: this.editId})
          }
        } else {
          await this.actionCreatePrinter(data)
        }
        await this.actionFetchPrinters();
        this.closeModal();
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
