var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"color":"primary","dark":"","app":""}},[_c('v-app-bar-title',[_vm._v(" "+_vm._s(_vm.nomeEstabelecimento)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{name: _vm.ROUTE_NAMES.LISTA_IMPRESSORAS},"loading":!_vm.machineId,"disabled":!_vm.machineId}},on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}])},[_c('span',[_vm._v("Impressoras")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.changeSoundPlay0}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.iconSoundPlay0))])],1)]}}])},[_c('span',[_vm._v("Som de pedido")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.changeSoundPlay1}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.iconSoundPlay1))])],1)]}}])},[_c('span',[_vm._v("Som de impressão")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.logout}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Sair")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }