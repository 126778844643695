<template>
<v-app-bar color="primary" dark app>
  <v-app-bar-title>
    {{ nomeEstabelecimento }}
  </v-app-bar-title>
  <v-spacer></v-spacer>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
    <v-btn
      icon
      v-on="on"
      :to="{name: ROUTE_NAMES.LISTA_IMPRESSORAS}"
      :loading="!machineId"
      :disabled="!machineId"
    >
      <v-icon>mdi-printer</v-icon>
    </v-btn>
    </template>
    <span>Impressoras</span>
  </v-tooltip>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
        @click="changeSoundPlay0"
      >
        <v-icon>{{ iconSoundPlay0 }}</v-icon>
      </v-btn>
    </template>
    <span>Som de pedido</span>
  </v-tooltip>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
        @click="changeSoundPlay1"
      >
        <v-icon>{{ iconSoundPlay1 }}</v-icon>
      </v-btn>
    </template>
    <span>Som de impressão</span>
  </v-tooltip>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
        @click="logout"
      >
          <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <span>Sair</span>
  </v-tooltip>
</v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Types } from '@/store';
import { ROUTE_NAMES } from '../router';
const { GETTERS, ACTIONS } = Types;
export default {
  data: () => ({
    ROUTE_NAMES:ROUTE_NAMES
  }),

  computed:{
    ...mapGetters({
      nomeEstabelecimento: GETTERS.NOME_ESTABELECIMENTO,
      soundPlay0: GETTERS.SOUND_PLAY_0,
      soundPlay1: GETTERS.SOUND_PLAY_1,
      idEstabelecimento: GETTERS.ID_ESTABELECIMENTO,
      machineId: GETTERS.MACHINE_ID,
      token: GETTERS.TOKEN,
    }),
    iconSoundPlay0() {
      return this.soundPlay0 ? 'mdi-volume-high' : 'mdi-volume-off';
    },
    iconSoundPlay1() {
      return this.soundPlay1 ? 'mdi-speaker-wireless' : 'mdi-speaker-off';
    }
  },
  methods: {
    ...mapActions({
      actionCloseSnackbar: ACTIONS.CLOSE_SNACKBAR,
      actionLogout: ACTIONS.LOGOUT,
      actionSaveSoundPlay0: ACTIONS.SAVE_SOUND_PLAY_0,
      actionSaveSoundPlay1: ACTIONS.SAVE_SOUND_PLAY_1
    }),
    changeSoundPlay0() {
      this.actionSaveSoundPlay0({
        soundPlay0: this.soundPlay0 ? 0 : 1
      });
    },
    logout() {
      this.actionLogout()
        .then(() => this.$router.push({ name: ROUTE_NAMES.LOGIN }))
    },
    changeSoundPlay1() {
      this.actionSaveSoundPlay1({
        soundPlay1: this.soundPlay1 ? 0 : 1
      });
    }
  }
}
</script>

<style>

</style>