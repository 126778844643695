<template>
<v-container>
  <v-row justify="center">
    <v-col cols="6">
    <v-form
      v-model="formValido"
      ref="form"
      @submit.prevent="login"
    >
      <v-card 
        :loading="loading"
      >
        <v-card-title>
          Social Place
        </v-card-title>
        <v-card-text>
            <v-text-field
              v-model="email"
              label="Email"
              :rules="[v => !!v || 'Campo obrigatório']"
              required
            />
            <v-text-field
              v-model="senha"
              label="Senha"
              type="password"
              :rules="[v => !!v || 'Campo obrigatório']"
              required
            />
            <v-text-field
              v-model="nick"
              label="Apelido"
              :rules="[v => !!v || 'Campo obrigatório']"
              required
            />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="success"
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            Entrar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { ROUTE_NAMES } from '../router/names';
import { Types } from '../store';

const { ACTIONS } = Types;

export default {
  name: 'Login',

  data() {
    return {
      email: '',
      senha: '',
      nick: 'Caixa',
      formValido: false,
      loading: false
    }
  },

  methods: {
    ...mapActions({
      openSnackbar: ACTIONS.OPEN_SNACKBAR,
      actionLogin: ACTIONS.LOGIN,
    }),
    login() {
      this.$refs.form.validate();
      
      if (!this.formValido) {
        return;
      }

      this.loading = true;
      const payload = {
        email: this.email,
        senha: this.senha,
        nick: this.nick,
      };

      this.actionLogin(payload)
        .then(() => this.$router.push({ name: ROUTE_NAMES.HOME }))
        .catch(e => this.openSnackbar({ message: e, millisecs: 7000 }))
        .finally(() => this.loading = false);
    }
  }
}
</script>
