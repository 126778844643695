import Vue from 'vue';
import Vuex from 'vuex';

import { ACTIONS, GETTERS, MUTATIONS } from './types';

import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

export const Types = {
  ACTIONS,
  GETTERS,
  MUTATIONS
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
