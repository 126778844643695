export const GETTERS = {
  SHOW_SNACKBAR: 'SHOW_SNACKBAR',
  MESSAGE_SNACKBAR: 'MESSAGE_SNACKBAR',
  IS_AUTH: 'IS_AUTH',
  TOKEN: 'TOKEN',
  NOME_USUARIO: 'NOME_USUARIO',
  NOME_ESTABELECIMENTO: 'NOME_ESTABELECIMENTO',
  ID_ESTABELECIMENTO: 'ID_ESTABELECIMENTO',

  RUNTIME_VERSION: 'RUNTIME_VERSION',
  NICK: 'NICK',
  PRINTER_NAME: 'PRINTER_NAME',
  PRINTER_TYPE: 'PRINTER_TYPE',
  MACHINE_ID: 'MACHINE_ID',
  SOUND_PLAY_0: 'SOUND_PLAY_0',
  SOUND_PLAY_1: 'SOUND_PLAY_1',

  PRINTERS: 'PRINTERS',
};

export const MUTATIONS = {
  OPEN_SNACKBAR: 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',

  SET_RUNTIME_VERSION: 'SET_RUNTIME_VERSION',
  SET_PRINTERS: 'SET_PRINTERS',
  SET_PRINTER_NAME: 'SET_PRINTER_NAME',
  SET_PRINTER_TYPE: 'SET_PRINTER_TYPE',
  SET_MACHINE_ID: 'SET_MACHINE_ID',
  SET_SOUND_PLAY_0: 'SET_SOUND_PLAY_0',
  SET_SOUND_PLAY_1: 'SET_SOUND_PLAY_1',
};

export const ACTIONS = {
  OPEN_SNACKBAR: 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',
  
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOAD_LOGIN_INFO: 'LOAD_LOGIN_INFO',
  SAVE_PRINTER_NAME: 'SAVE_PRINTER_NAME',
  SAVE_PRINTER_TYPE: 'SAVE_PRINTER_TYPE',
  SAVE_MACHINE_ID: 'SAVE_MACHINE_ID',
  SAVE_SOUND_PLAY_0: 'SAVE_SOUND_PLAY_0',
  SAVE_SOUND_PLAY_1: 'SAVE_SOUND_PLAY_1',

  TEST_PRINTER_RECEIPT: 'TEST_PRINTER_RECEIPT',
  SET_PRINTER_DEFAULT: 'SET_PRINTER_DEFAULT',
  CREATE_PRINTER: 'CREATE_PRINTER',
  UPDATE_PRINTER: 'UPDATE_PRINTER',
  FETCH_PRINTERS: 'FETCH_PRINTERS',
  DELETE_PRINTER: 'DELETE_PRINTER',
};
