const state = {
  showSnackbar: false,
  messageSnackbar: '',
  timeoutSnackbar: 0,

  isAuth: false,
  token: '',
  nomeUsuario: '',
  nomeEstabelecimento: '',
  idEstabelecimento: '',

  runtimeVersion: '',
  nick: '',
  printerName: '',
  printerType: '',
  machineId: '',
  soundPlay0: 1,
  soundPlay1: 1,

  printers: [],
};

export default state;
