export const MARCAS_IMPRESSORAS = {
  bema: 'Bematech',
  daruma: 'Daruma',
  elgin: 'Elgin',
  epson: 'Epson',
  perto: 'Perto',
  sweda: 'Sweda',
  tanca: 'Tanca',
  other: 'Outra'
};

export const OPTIONS_MARCAS_IMPRESSORAS = Object.keys(MARCAS_IMPRESSORAS).map(v => ({
  text: MARCAS_IMPRESSORAS[v],
  value: v
}));
