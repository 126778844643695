export async function listPrinters() {
  if (typeof getPrinters !== 'function') {
    return [];
  }
  // eslint-disable-next-line
  return await getPrinters();
}

export async function sendPrint(receipt, printers, amount_prints) {
  if (typeof printReceipt === 'function') {
    const arg = JSON.stringify({
      receipt,
      printers,
      amount_prints
    })
    // eslint-disable-next-line
    await printReceipt(arg);
  }
}
