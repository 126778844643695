import axios from 'axios';
import { sha256 } from 'js-sha256';

import Cache from '../utils/cache';
import { ACTIONS, MUTATIONS } from './types';
import { sendPrint } from '@/utils/print';

const actions = {
  [ACTIONS.OPEN_SNACKBAR] ({ commit, dispatch }, payload) {
    const {
      message,
      millisecs = 3000
    } = payload;
    
    const timeout = setTimeout(
      () => dispatch(ACTIONS.CLOSE_SNACKBAR), 
      millisecs
    );
    
    commit(MUTATIONS.OPEN_SNACKBAR, {
      message,
      timeout
    });
  },

  [ACTIONS.CLOSE_SNACKBAR] ({ commit, state }) {
    clearTimeout(state.timeoutSnackbar);
    commit(MUTATIONS.CLOSE_SNACKBAR);
  },

  [ACTIONS.LOGIN] ({ commit, state }, payload) {
    const {
      runtimeVersion
    } = state;
    
    const {
      email,
      senha,
      nick
    } = payload;

    return new Promise((resolve, reject) => {
      if (!runtimeVersion) {
        return reject('Runtime não reconhecido. Por favor, feche e abra novamente o app.');
      }

      const data = {
        email: email.toLowerCase().trim(),
        password: sha256(senha),
        object_type: 0,
        facebook_login: ''
      };

      axios.post('https://api.socialplace.com.br/api/login', data)
        .then(res => {
          if (!res.data.ok) {
            return reject('Credenciais Inválidas');
          }

          const {
            token,
            managername: nomeUsuario,
            restaurantname: nomeEstabelecimento,
            restaurant: idEstabelecimento
          } = res.data.data;
          
          (async function () {
            try {
              const ch = new Cache(idEstabelecimento);
  
              await ch.loadConfigCache();
              ch.nick = nick;
              await ch.saveConfigCache();

              await ch.loadLoginCache();
  
              const printerName = ch.printerName;
              const printerType = ch.printerType;
              const machineId = ch.machineId;
              const soundPlay0 = ch.soundPlay0;
              const soundPlay1 = ch.soundPlay1;
  
              commit(MUTATIONS.LOGIN, {
                token,
                nomeUsuario,
                nomeEstabelecimento,
                idEstabelecimento,
                nick,
                printerName,
                printerType,
                machineId,
                soundPlay0,
                soundPlay1
              });
  
              ch.nomeUsuario = nomeUsuario;
              ch.nomeEstabelecimento = nomeEstabelecimento;
              ch.token = token;

              await ch.saveLoginCache();
  
              return resolve();
            }
            catch (e) {
              console.log(e);
              reject();
            }
          })();
        })
        .catch(() => reject('Erro de comunicação'));
    });
  },

  [ACTIONS.LOGOUT] ({ commit }) {
    return new Promise(resolve => {
      commit(MUTATIONS.LOGOUT);
      let ch = new Cache();
      ch.logout()
      resolve();
    });
  },

  [ACTIONS.SAVE_PRINTER_NAME] ({ commit }, payload) {
    const {
      printerName
    } = payload;

    (async function () {
      try {
        const ch = new Cache();
        await ch.loadConfigCache();

        ch.printerName = printerName;
        await ch.saveConfigCache();

        return commit(MUTATIONS.SET_PRINTER_NAME, { printerName });
      }
      catch (e) {
        console.log(e);
      }
    })();
  },

  [ACTIONS.SAVE_PRINTER_TYPE] ({ commit }, payload) {
    const {
      printerType
    } = payload;

    (async function () {
      try {
        const ch = new Cache();
        await ch.loadConfigCache();

        ch.printerType = printerType;
        await ch.saveConfigCache();
  
        return commit(MUTATIONS.SET_PRINTER_TYPE, { printerType });
      }
      catch (e) {
        console.log(e);
      }
    })();
  },

  [ACTIONS.SAVE_SOUND_PLAY_0] ({ commit }, payload) {
    const {
      soundPlay0
    } = payload;

    (async function () {
      try {
        const ch = new Cache();
        await ch.loadConfigCache();

        ch.soundPlay0 = soundPlay0;
        await ch.saveConfigCache();
  
        return commit(MUTATIONS.SET_SOUND_PLAY_0, { soundPlay0 });
      }
      catch (e) {
        console.log(e);
      }
    })();
  },

  [ACTIONS.SAVE_SOUND_PLAY_1] ({ commit }, payload) {
    const {
      soundPlay1
    } = payload;

    (async function () {
      try {
        const ch = new Cache();
        await ch.loadConfigCache();

        ch.soundPlay1 = soundPlay1;
        await ch.saveConfigCache();
  
        commit(MUTATIONS.SET_SOUND_PLAY_1, { soundPlay1 });
      }
      catch (e) {
        console.log(e);
      }
    })();
  },

  [ACTIONS.SAVE_MACHINE_ID] ({ commit, state }, payload) {
    const {
      idEstabelecimento
    } = state;

    const {
      machineId
    } = payload;

    (async function () {
      try {
        const ch = new Cache(idEstabelecimento);
        await ch.loadLoginCache();

        ch.machineId = machineId;
        await ch.saveLoginCache();
  
        commit(MUTATIONS.SET_MACHINE_ID, { machineId });
      }
      catch (e) {
        console.log(e);
      }
    })();
  },

  [ACTIONS.LOAD_LOGIN_INFO] ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      (async function () {
        try {
          const ch = new Cache();
  
          await ch.loadConfigCache();
          
          if (!ch.runtimeVersion) {
            dispatch(ACTIONS.OPEN_SNACKBAR, {
              message: 'Runtime não reconhecido. Por favor, feche e abra novamente o app.',
              millisecs: 7000
            });
            return reject();
          }
          else {
            commit(MUTATIONS.SET_RUNTIME_VERSION, {
              runtimeVersion: ch.runtimeVersion
            });
          }
          
          if (!ch.idEstabelecimento) {
            return reject();
          }
  
          await ch.loadLoginCache();
    
          commit(MUTATIONS.LOGIN, {
            token: ch.token,
            nomeUsuario: ch.nomeUsuario,
            nomeEstabelecimento: ch.nomeEstabelecimento,
            idEstabelecimento: ch.idEstabelecimento,
            nick: ch.nick,
            printerName: ch.printerName,
            printerType: ch.printerType,
            machineId: ch.machineId,
            soundPlay0: ch.soundPlay0,
            soundPlay1: ch.soundPlay1
          });
    
          return resolve();
        }
        catch (e) {
          console.log(e);
          reject();
        }
      })();
    });
  },

  async [ACTIONS.FETCH_PRINTERS] ({ commit, state }) {
    const {
      machineId,
      token
    } = state;
    
    const config = {
      params: {
        machine_id: machineId
      },
      headers: {
        'x-auth-token': token
      },
      timeout: 5000
    };
    try {
      const res = await axios.get('https://print.socialplace.com.br/printers', config)
      commit(MUTATIONS.SET_PRINTERS, {
        printers: res.data
      })
    } catch (error) {
      console.log(error);
      commit(MUTATIONS.SET_PRINTERS, {
        printers: []
      });
    }
  },
  async [ACTIONS.SET_PRINTER_DEFAULT] ({ state }, payload) {
    const {
      token,
    } = state;

    const {
      printerId
    } = payload;

    const config = {
      params: {
        id: printerId
      },
      headers: {
        'x-auth-token': token
      },
      timeout: 5000
    };

    try {
      await axios.put('https://print.socialplace.com.br/printers', {}, config)
    } catch (error) {
      console.log(error)
    }

  },

  [ACTIONS.CREATE_PRINTER] ({ state }, payload) {
    return new Promise((resolve, reject) => {
      const {
        machineId,
        token
      } = state;
  
      const {
        nick,
        printerName,
        printerType,
        printerDefault
      } = payload;
  
      const data = {
        machine_id: machineId,
        printer_name: printerName,
        printer_type: printerType,
        printer_default: printerDefault,
        nick
      };
  
      const config = {
        headers: {
          'x-auth-token': token
        },
        timeout: 5000
      };
  
      axios.post('https://print.socialplace.com.br/printers', data, config)
        .then(() => resolve())
        .catch(e => reject(e));
    });
  },

  async [ACTIONS.UPDATE_PRINTER] ({ state }, payload) {
    const {
      token
    } = state;

    const {
      printerId,
      printerName,
      printerType,
      printerDefault,
      nick,
    } = payload;

    const data = {
      printer_name: printerName,
      printer_type: printerType,
      printer_default: printerDefault,
      nick,
    };

    const config = {
      headers: {
        'x-auth-token': token
      },
      timeout: 5000
    };

    try {
      await axios.put(`https://print.socialplace.com.br/printers/${printerId}`, data, config)
    } catch (error) {
      console.log(error)
    }
  },

  [ACTIONS.DELETE_PRINTER] ({state}, {printerId}){
    const {
      token
    } = state;

    const config = {
      headers: {
        'x-auth-token': token
      },
      timeout: 5000
    };

    return axios.delete(`https://print.socialplace.com.br/printers/${printerId}`, config)
  },

  [ACTIONS.TEST_PRINTER_RECEIPT] ({ state }, payload) {
    const {
      token,
    } = state;

    const {
      id,
      printer_name
    } = payload;

    const data = {
      id,
      token,
      receipt: 'teste',
    };

    const config = {
      timeout: 5000
    };

    axios.post('https://print.socialplace.com.br/receipts/generate', data, config)
      .then(res => {
        let {
          receipt,
          amount_prints = 1
        } = res.data;
        
        if (amount_prints < 1) {
          amount_prints = 1;
        }

        sendPrint(receipt, [printer_name], amount_prints);
      })
      .catch(e => {
        console.log(e);
      });
  }
};

export default actions;
